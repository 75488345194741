// Filename - components/Footer.js

import React from "react";
import {
	Box,
	FooterContainer,
	Row,
	Column,
	FooterLink,
	Heading,
} from "../css/FooterStyles";

const Footer = () => {
	return (
		<Box >
			<h1
				style={{
					color: "white",
					textAlign: "center",
					marginBottom: "3rem",
                    fontSize: "2rem"
				}}
			>
				Support Information
			</h1>
			<FooterContainer >
				<Row>
					<Column>
						<Heading>About Us</Heading>

						<FooterLink href="/privacy">
							Privacy
						</FooterLink>
					</Column>

				
					<Column>
						<Heading>Contact Us</Heading>


						<FooterLink href="/get-started">
							Try the application
						</FooterLink>
						


						<FooterLink href="/support">
							Support Ticket
						</FooterLink>
						<p href="#">
							Email us: support@keysmediagroup.com
						</p>
					</Column>
					
				</Row>
			</FooterContainer>

            <h1 className="keystudios center">Key's Media Group Ltd. ©</h1>
		</Box>
	);
};
export default Footer;
