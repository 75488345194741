import React from "react";
import { Link } from "react-router-dom";
import Header from "../utilities/Header";
import { Stack } from "@mui/system";
function Content() {
  return (
    <main>
      <Header />

      <div className="center ">{/* <h1>Chefly!</h1>  */}</div>

      <div className="center ">
        <div className="center ">
          <Stack>
            <h1>It's Bold. </h1>
            <h1>It's You.</h1>
            <h1>It's Chefly!</h1>
          </Stack>
        </div>
        <img
          className="main_photo"
          src="https://images.unsplash.com/photo-1607430986197-d60a579b87cd?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="hero"
          width={300}
        />
        <div className="center">
          <h1>Welcome!</h1>
        </div>{" "}
      </div>

      <div className="center">
        <div className="rebranding">
          <p className="smallRebrand">
            Available on iOS.
            <br />
          </p>
          <p className="keystudios">
            Site under construction.
            <br />
          </p>
          We're just getting started
          <br />
        </div>
      </div>
    </main>
  );
}

export default Content;
