import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import "../css/navbar.css";
import chefly from '../../img/chefly.png'
const Navbar = () => {
 const [showMenu, setShowMenu] = useState(false);

 const toggleMenu = () => {
   setShowMenu(!showMenu);
 };

  const closeMenuOnMobile = () => {
    if (window.innerWidth <= 1150) {
      setShowMenu(false);
    }
  };
 return (
     <nav className="">
      
       <NavLink to="/" className="nav__logo">

       </NavLink>

       <div
         className={`nav__menu ${showMenu ? "show-menu" : ""}`}
         id="nav-menu"
       >
         <ul className="nav__list">
         <div className="logo" style={{display: "flex", margin: 10, alignContent: "baseline", alignItems: "center"}}>
        <img className="navLogo" src={chefly} alt='logo' width={80}></img>
        
         <h1 style={{margin: 10, display: "flex", flexDirection: "row", fontSize: "larger", color: "black"}}>The Cookbook for Everyone!</h1>
         </div>
         <li className="nav__item">
             <NavLink to="/" className="nav__link"  onClick={closeMenuOnMobile}>
               Home
             </NavLink>
           </li>
           <li className="nav__item">
             <NavLink to="/our_mission" className="nav__link"  onClick={closeMenuOnMobile}>
               Our Mission
             </NavLink>
           </li>
           <li className="nav__item">
             <NavLink
               to="/recipes"
               className="nav__link"
                onClick={closeMenuOnMobile}
             >
               New Recipes
             </NavLink>
           </li>




         </ul>
         <div className="nav__close" id="nav-close" onClick={toggleMenu}>
           <IoClose />
         </div>
       </div>

       <div  className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>

        <h2 className='navMenuTitle' style={{margin: 0}}>Chefly! <p style={{fontSize: 15}}> ©</p></h2>
        <p></p>
         <IoMenu />
       </div>
     </nav>
 );
};
export default Navbar;
