import wave from '../../img/wave.png'
import { Link } from 'react-router-dom';
function Our_Mission() {
  return (
    

<main>

<div className="header"></div>

<div className='bg-text form'>


<div className='aboutHeader'>

    <h1 className='intro'>Chefly!</h1>
    <h1 style={{display: "flex", flexDirection: "row"}} className='intro'>I'm Key.</h1>
    <h1 className='intro'>The Cookbook For Everyone!</h1><br />

    
</div>
    <p className='about'/>

</div>

</main>

  )
}

export default Our_Mission;